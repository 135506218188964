import Empty from '@/components/Empty.vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const lazyLoad = (path: string) => () => import(`@/views/${path}.vue`)

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: lazyLoad('home/HomeView')
  },
  {
    path: '/oauth/:thirdParty/callback',
    name: 'oauth-callback',
    component: lazyLoad('OauthCallbackView')
  },
  {
    path: '/exceptions',
    component: Empty,
    children: [
      {
        path: '404',
        name: 'exception-not-found',
        component: lazyLoad('exceptions/NotFoundView')
      },
      {
        path: '/(.*)',
        redirect: { name: 'exception-not-found' }
      }
    ]
  },
  {
    path: '/(.*)',
    redirect: { name: 'exception-not-found' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
