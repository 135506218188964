import { User } from '@/utils/types/app'
import { LoginActionData, State } from '@/utils/types/store'
import { ActionContext, createStore } from 'vuex'

export default createStore<State>({
  state: {
    isLogin: false,
    token: null,
    user: null
  },
  mutations: {
    setIsLogin(state: State, isLogin: boolean): void {
      state.isLogin = isLogin
    },
    setToken(state: State, token: string): void {
      state.token = token
    },
    setUser(state: State, user: User): void {
      state.user = user
    }
  },
  actions: {
    login(
      context: ActionContext<State, State>,
      { token, user }: LoginActionData
    ): void {
      context.commit('setIsLogin', true)
      context.commit('setToken', token)
      context.commit('setUser', user)
    }
  }
})
