<template>
  <router-view />
</template>

<style lang="scss">
html {
  overflow: hidden !important;
}

html,
body {
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  background-color: #f6f6f6;
}

.v-btn {
  &__content {
    line-height: 0;
  }
}
</style>
